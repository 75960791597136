import slugbunniesKeiron from '../assets/images/slugbunnies/0.png'
import slugbunniesSheila from '../assets/images/slugbunnies/1.png'
import slugbunniesAnthony from '../assets/images/slugbunnies/2.png'
import slugbunniesRachel from '../assets/images/slugbunnies/3.png'
import slugbunniesBen from '../assets/images/slugbunnies/4.png'
import subSebastian from '../assets/images/icon_sub_sebastian.png'
import BackgroundImage from '../assets/images/backgrounds/slugbunnies_bg_underwater_min.png'
import BackgroundImageSmall from '../assets/images/backgrounds/slugbunnies_bg_underwater_tiny.png'
import '../App.css'
import { scroller, animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import DefaultContainer from '../components/common/DefaultContainer'
import { useEffect } from 'react'
import TeamDescriptionWithImage from '../components/common/TeamDescriptionWithImage'
import { Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Fade from '../reveal/in-and-out/Fade'
import LazyImage from '../components/common/LazyImage'

const MAX_WIDTH = 1200

function Team() {
  const theme = useTheme()

  const location = useLocation()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1)
      if (section) {
        scroller.scrollTo(section, { smooth: true })
      }
    } else {
      animateScroll.scrollToTop()
    }
  }, [location])
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <LazyImage
        src={BackgroundImage}
        lqip={BackgroundImageSmall}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          minHeight: '100vh',
          objectPosition: 'center top',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />
      <DefaultContainer
        style={{
          marginTop: isMobile ? '48px' : '148px',
          maxWidth: `${MAX_WIDTH}px`,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Fade bottom cascade>
          <Typography
            align="center"
            variant={isMobile ? 'h5' : 'h4'}
            pl={isMobile ? 2 : 10}
            pr={isMobile ? 2 : 10}
            mb={5}
            mt={5}
            sx={{
              textShadow: '0px 0px 32px white',
              color: theme.palette.primary.main,
              fontFamily: '"Chelsea Market", "Helvetica", "Arial", sans-serif',
              fontWeight: 500,
            }}
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              display="inline"
              component="p"
              sx={{
                textShadow: '0px 0px 32px white',
                fontFamily:
                  '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                fontWeight: 700,
                fontStyle: 'italic',
              }}
            >
              Slugbunnies
            </Typography>{' '}
            is a family friendly brand built by entertainment professionals who
            are kids at heart.
          </Typography>
        </Fade>
      </DefaultContainer>

      <DefaultContainer
        className="bgGlow"
        style={{
          maxWidth: `${MAX_WIDTH}px`,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TeamDescriptionWithImage
          title={'Keiron “Big Brain” Gulrajani, Developer & Founder'}
          reversed
          imageSrc={slugbunniesKeiron}
        >
          An experienced developer and self professed nerd, Keiron has been
          coding since 2004. He switched from Android development to smart
          contract development in 2018 and never looked back. He’s the founder
          of MurAll (Paint) and Frames, and has a love for art and design. As a
          hobby, Keiron plays guitar… and not the ‘air guitar’ version. The full
          meal deal, heavy metal and all.
          <br />
          <br />
          <Link href="https://murall.art" target="_blank" rel="noreferrer">
            https://murall.art
          </Link>
        </TeamDescriptionWithImage>
      </DefaultContainer>

      <DefaultContainer
        className="bgGlow"
        style={{
          marginTop: '48px',
          maxWidth: `${MAX_WIDTH}px`,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TeamDescriptionWithImage
          title={'Sheila “the Scribe” Rogerson, Writer & Founder'}
          imageSrc={slugbunniesSheila}
        >
          An award-winning screenwriter and producer, Sheila is also a cleverly
          disguised five-year old. She has written, developed, and story edited
          over 70 different animated and live-action kids’ series for Netflix,
          Disney, Nickelodeon, PBS, Treehouse, CBC, NBC, BBC KIDS, Discovery
          Kids and more – including MAX & RUBY, DINO RANCH, CORN & PEG, PAW
          PATROL, ALMA’S WAY, CAT IN THE HAT KNOWS A LOT ABOUT THAT, IMAGINATION
          MOVERS, INTERSTELLAR ELLA and BUILDER BROTHERS DREAM FACTORY.
          <br />
          <br />
          <Link
            href="https://www.imdb.com/name/nm0227837/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.imdb.com/name/nm0227837/
          </Link>
        </TeamDescriptionWithImage>
      </DefaultContainer>
      <DefaultContainer
        className="bgGlow"
        style={{
          marginTop: '48px',
          maxWidth: `${MAX_WIDTH}px`,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TeamDescriptionWithImage
          title={'Anthony “Idea Guy” Artibello, Producer & Founder'}
          imageSrc={slugbunniesAnthony}
          reversed
        >
          LIdea Merchant. Professional writer. Worldbuilding and web3 are his
          passion. Anthony has written for several animated preschool series,
          including MAX & RUBY, REV & ROLL and CORN & PEG, where he also served
          as co-story editor. Anthony is also the CCO of Wild Media
          Entertainment, a boutique production and VFX studio. Anthony love
          comics, anime and NFTs. He’s also fluent in Slugbunnese.
          <br />
          <br />
          <Link
            href="https://wildmediaent.com/about-us/team/"
            target="_blank"
            rel="noreferrer"
          >
            https://wildmediaent.com/about-us/team/
          </Link>
        </TeamDescriptionWithImage>
      </DefaultContainer>
      <DefaultContainer
        className="bgGlow"
        style={{
          marginTop: '48px',
          maxWidth: `${MAX_WIDTH}px`,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TeamDescriptionWithImage
          title={'Rachel “the Artisan” Chalk, Artist & Founder'}
          imageSrc={slugbunniesRachel}
        >
          Rachel is an Animation Design Artist who has been designing characters
          for animated TV shows for over a decade while doing a wide variety of
          freelance projects – including illustrating a children’s book,
          designing kids wall stickers, website art, wedding invitations,
          fashion designs, and concept art. She loves to travel, create art
          projects with her husband, Ben, play the piano, and watch her toddler
          throw rocks in the lake.
        </TeamDescriptionWithImage>
      </DefaultContainer>
      <DefaultContainer
        className="bgGlow"
        style={{
          marginTop: '48px',
          maxWidth: `${MAX_WIDTH}px`,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TeamDescriptionWithImage
          title={'Ben “the Maestro” Hu, Artist & Founder'}
          imageSrc={slugbunniesBen}
          reversed
        >
          Ben has been working on animated TV shows and games since 2006 in a
          variety of roles, most recently in character design, but also in
          animation, layout, background painting, directing, posing, and
          storyboards. His latest and greatest role is being a fully involved
          dad, all made possible because of his amazing wife, Rachel.
        </TeamDescriptionWithImage>
      </DefaultContainer>
      <DefaultContainer
        className="bgGlow"
        style={{
          marginTop: '48px',
          maxWidth: `${MAX_WIDTH}px`,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <TeamDescriptionWithImage
          title={'Sebastian Seahart, “Famous Oceanographer”'}
          imageSrc={subSebastian}
        >
          An oceanographer and adventurer, Sebastian Seaheart first discovered
          the Slugbunnies in early 2022. The discovery brought him much fame and
          fortune, and since then he has travelled the globe promoting his
          autobiography, signing autographs, and regaling his adoring fans with
          tales of his daring seafaring adventures. Sebastian was last seen in
          the UK, searching for the Loch Ness monster.
        </TeamDescriptionWithImage>
      </DefaultContainer>
    </Box>
  )
}

export default Team

import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import SectionTitle from '../components/common/SectionTitle'
import Accordion from '../components/common/Accordion'
import WidthLimiter from '../components/common/WidthLimiter'

const FormLink = ({ text }) => {
  return (
    <Link href="https://discord.gg/murall" target="_blank" rel="noreferrer">
      {text}
    </Link>
  )
}

const Link = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
`

const FAQ_ITEMS = [
  {
    title: 'What are Slugbunnies?',
    content:
      'SLUGBUNNIES is a collection of 10,000 family-friendly, ‘gently animated’, musical NFTs.',
  },
  {
    title: 'What does it cost to mint a Slugbunnies NFT?',
    content:
      '(TBD. ETH equivalent to $150 at time of sale + network transaction fees)',
  },
  {
    title: `"Wen mint??"`,
    content: <>We're working on it!</>,
  },
  {
    title: `"Wen Slugbunnies show??"`,
    content: <>We're working on it!</>,
  },
  {
    title: 'Can I get whitelisted?',
    content: (
      <>
        <p>
          Yes! Check out our <FormLink text="Discord channel" /> for details
        </p>
      </>
    ),
  },
  {
    title: 'As a Slugbunnies NFT holder, what can I do with my Slugbunny?',
    content: (
      <>
        TBD: (use the image for x, monitise the image for y, etc. link out to
        full legal terms)
      </>
    ),
  },
]

const FaqSection = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const items = FAQ_ITEMS
  return (
    <WidthLimiter>
      <Container noPadding={isMobile}>
        <SectionTitle title="FAQ" />
        <Accordion items={items} />
      </Container>
    </WidthLimiter>
  )
}

const Container = styled.div`
  display: flex:
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(16)};
  padding-left: ${({ theme, noPadding }) =>
    noPadding ? 0 : theme.spacing(16)};
  padding-right: ${({ theme, noPadding }) =>
    noPadding ? 0 : theme.spacing(16)};
`

export default FaqSection

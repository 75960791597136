import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './root-reducer'

export default function configureAppStore (preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware => getDefaultMiddleware()
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./root-reducer.js', () =>
      store.replaceReducer(rootReducer)
    )
  }

  return store
}

import logoImage from '../assets/images/logos/slugbunny_logo_square.png'
import BackgroundImage from '../assets/images/backgrounds/slugbunnies_bg_underwater_min.png'
import BackgroundImageSmall from '../assets/images/backgrounds/slugbunnies_bg_underwater_tiny.png'
import ClamshellImage from '../assets/images/clamshell_min.png'
import '../App.css'
import { scroller, animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import DefaultContainer from '../components/common/DefaultContainer'
import { useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import Fade from '../reveal/in-and-out/Fade'
import ImageWithDescription from '../components/common/ImageWithDescription'
import LazyImage from '../components/common/LazyImage'

const MAX_WIDTH = 1200

function Clamshell () {
  const theme = useTheme()

  const location = useLocation()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1)
      if (section) {
        scroller.scrollTo(section, { smooth: true })
      }
    } else {
      animateScroll.scrollToTop()
    }
  }, [location])
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      <LazyImage
        src={BackgroundImage}
        lqip={BackgroundImageSmall}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          minHeight: '100vh',
          objectPosition: 'center top',
          objectFit: 'cover',
          zIndex: -1
        }}
      />

      <Box
        sx={{
          marginTop: isMobile ? '48px' : '148px',
          width: '100%',
          height: '100%',
          minHeight: `calc(100vh - ${isMobile ? '48px' : '148px'} - 72px)`
        }}
      >
        <DefaultContainer
          style={{
            marginTop: isMobile ? '48px' : '148px',
            maxWidth: `${MAX_WIDTH}px`,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{
              width: '100%',
              height: '100%'
            }}
          >
            <Grid
              item
              xs={12}
              pl={2}
              pr={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                style={{
                  width: '100%',
                  maxWidth: '400px',
                  objectFit: 'contain'
                }}
                src={ClamshellImage}
                alt=''
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Fade bottom cascade>
                <Typography
                  align='center'
                  variant={isMobile ? 'h5' : 'h4'}
                  pl={isMobile ? 2 : 10}
                  pr={isMobile ? 2 : 10}
                  mb={10}
                  mt={10}
                  sx={{
                    color: theme.palette.primary.main,
                    textShadow: '0px 0px 32px white',
                    fontFamily:
                      '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500
                  }}
                >
                  Welcome to the Clamshell – the place for news and future
                  announcements!
                </Typography>
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <Divider variant='middle' />
            </Grid>
          </Grid>
        </DefaultContainer>

        <DefaultContainer
          className='bgGlow'
          style={{
            marginTop: '48px',
            maxWidth: `${MAX_WIDTH}px`,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <ImageWithDescription
            imageSrc={logoImage}
            imageStyle={{
              width: '100px',
              height: '100px'
            }}
            imageSm={2}
            textSm={10}
          >
            We are developing animated content in the kids’ space based on the
            Slugbunnies brand. Pure delightful entertainment.
          </ImageWithDescription>
        </DefaultContainer>
      </Box>
    </Box>
  )
}

export default Clamshell

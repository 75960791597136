import React, { useState } from 'react'
import { AppBar, Toolbar } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import SocialLinks from '../SocialLinks'
import Logo from './Logo'
import DesktopNavLinks from '../../desktop/Navbar/Links'
import MobileNavLinks from '../../mobile/Navbar/Links'
import { useTheme } from '@mui/material/styles'

const NavigationBar = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const scrollTriggered = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  })
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <AppBar
      position='fixed'
      elevation={scrollTriggered ? 4 : 0}
      sx={{
        backgroundImage: 'none',
        backgroundColor: theme =>
          scrollTriggered || isMobileMenuOpen
            ? theme.palette.background.default
            : 'transparent',
        transition: 'background-color 300ms linear'
      }}
    >
      <Toolbar
        sx={{
          alignItems: 'center',
          ...(isMobile && {
            padding: theme.spacing(1)
          }),
          // Override media queries injected by theme.mixins.toolbar
          ...(!isMobile && {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            '@media all': {
              minHeight: 60
            }
          })
        }}
      >
        <Logo />
        {!isMobile && (
          <DesktopNavLinks
            color={scrollTriggered ? 'inherit' : theme.palette.primary.main}
          />
        )}
        {isMobile ? (
          <MobileNavLinks
            isOpen={isMobileMenuOpen}
            setOpen={setMobileMenuOpen}
          />
        ) : (
          <>
            <SocialLinks />
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default NavigationBar

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
  Lazy
} from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'
import '../../App.css'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/virtual'
import { useEffect, useState } from 'react'

// install Virtual module
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
  Lazy
])

export default function Carousel (props) {
  const [swiper, setSwiper] = useState(null)

  useEffect(() => {
    if (swiper !== null) {
      // workaround for virtual slides
      swiper.on('slideChangeTransitionStart', function () {
        setTimeout(function () {
          swiper.animating = false
        }, 0)
      })
    }
  }, [swiper])

  const createContent = (content, index) => (
    <SwiperSlide
      key={'content' + index}
      virtualIndex={index}
      onClick={() => {
        props.onItemClick && props.onItemClick(content, index)
      }}
    >
      {/* {props.onCreateContent(content, index)} */}
      {({ isActive, isPrev, isNext, isVisible, isDuplicate }) => {
        isVisible &&
          props.onSlideVisible &&
          setTimeout(() => props.onSlideVisible(index, isVisible), 0)

        return props.renderSlideContent(content, index)
      }}
    </SwiperSlide>
  )

  return (
    <Swiper
      {...props}
      // install Swiper modules
      modules={[
        Navigation,
        Pagination,
        Scrollbar,
        A11y,
        Autoplay,
        Virtual,
        Lazy
      ]}
      loop={true}
      centeredSlides={true}
      onSwiper={swiper => setSwiper(swiper)}
      onSlideChange={() => console.log('slide change')}
      lazy={true}
    >
      {props.slides.map((slideContent, index) =>
        createContent(slideContent, index)
      )}
    </Swiper>
  )
}

import React from 'react'
import styled from 'styled-components'

const LayoutComingSoon = ({ children }) => {
  return (
    <LayoutContainer>
      <Content>
        {children}
      </Content>
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Content = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`

export default LayoutComingSoon

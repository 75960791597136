import React from 'react'
import styled from 'styled-components'
import SocialLinks from './SocialLinks'

const Footer = () => {
  return (
    <footer>
      <Container maxWidth='lg'>
        <SocialLinks />
      </Container>
    </footer>
  )
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: space-evenly;
  z-index: 100;
`

export default Footer

import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Typography, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Fade from '../../reveal/in-and-out/Fade'

export default function DescriptionWithImage (props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const createTitle = title => (
    <Typography
      variant={isMobile ? 'h4' : 'h3'}
      component='p'
      align='center'
      gutterBottom
      sx={{
        textShadow: '0px 0px 32px white',
        color: theme.palette.primary.main,
        fontFamily: '"Chelsea Market", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        ...props.titleSx
      }}
    >
      {title}
    </Typography>
  )

  const createDescription = description => (
    <Typography
      sx={{
        textShadow: '0px 0px 32px white',
        color: theme.palette.primary.main,
        fontFamily: '"Chelsea Market", "Helvetica", "Arial", sans-serif',
        fontWeight: 300
      }}
      variant={isMobile ? 'body1' : 'h5'}
      align='justify'
      component='p'
      gutterBottom
    >
      {props.children}
    </Typography>
  )

  const createImage = imageSrc =>
    props.svg ? (
      <div dangerouslySetInnerHTML={{ __html: imageSrc }} />
    ) : (
      <img
        style={{
          width: '100%',
          objectFit: 'contain'
        }}
        src={imageSrc}
        alt=''
      />
    )

  return (
    <Grid
      container
      spacing={6}
      direction={'row'}
      justify='space-between'
      alignItems='space-evenly'
      style={{
        width: '100%'
      }}
    >
      {!props.reversed || isMobile ? (
        <React.Fragment>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            item
            md={6}
            xs={12}
          >
            <Grid item xs>
              <Fade right>{createImage(props.imageSrc)}</Fade>
              {props.extraContent}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            spacing={2}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={12}>
              {props.title && createTitle(props.title)}
              {createDescription(props.description)}
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid
            container
            spacing={2}
            direction='row'
            justifyContent='center'
            alignItems='center'
            item
            xs={12}
            md={6}
          >
            <Grid item xs={12}>
              {props.title && createTitle(props.title)}

              {createDescription(props.description)}
            </Grid>
          </Grid>
          {props.extraContent}
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            item
            xs={12}
            md={6}
          >
            <Grid item xs>
              <Fade left>{createImage(props.imageSrc)}</Fade>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

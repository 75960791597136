import React from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import NavLink from '../../common/Navbar/NavLink'

const DesktopNavbarLinks = ({ color }) => {
  return (
    <ButtonGroup
      id='navButtons'
      sx={{
        flexGrow: 1,
        justifyContent: 'center'
      }}
      size='large'
      variant='text'
      aria-label='large primary button group'
    >
      <NavLink color={color} title={'TEAM'} to='/team' />
      <NavLink color={color} title={'LORE'} to='/lore' />
      <NavLink color={color} title={'MINT'} to='/mint' />
      <NavLink color={color} title={'SEAFLOOR'} to='/seafloor' />
      <NavLink color={color} title={'CLAMSHELL'} to='/clamshell' />
    </ButtonGroup>
  )
}

export default DesktopNavbarLinks

import React from 'react'
import { IconButton, Avatar } from '@mui/material'
import { Link } from 'react-router-dom'

import LogoImage from '../../../assets/images/logos/slugbunny_logo.png'

const Logo = () => {
  return (
    <Link to='/'>
      <IconButton
        sx={{
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent'
          },
          paddingTop: '0px',
          paddingBottom: '0px',
          margin: '0px'
        }}
      >
        <Avatar
          alt='Slugbunnies - home'
          src={LogoImage}
          variant='square'
          imgProps={{
            width: '100%',
            height: '100%',
            style: {
              objectFit: 'contain',
              objectPosition: 'center'
            }
          }}
          sx={{
            width: 54,
            height: 54,
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent'
            }
          }}
        />
      </IconButton>
    </Link>
  )
}

export default Logo

import React from 'react'
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme
} from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { grey, amber } from '@mui/material/colors'

const theme = createTheme({
  components: {
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 148,
          minHeight: 148
        }
      }
    }
  },
  palette: {
    type: 'light',
    primary: {
      main: '#662d91',
      dark: '#461f62'
    },
    secondary: {
      main: '#8ec9ee'
    },
    background: {
      default: '#672d91',
      paper: '#672d91'
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1324,
        xl: 2024
      }
    }
  },
  colors: {
    named: {
      red: '#ff1919',
      green: '#27AE60'
    }
  }
})

const RootThemeProvider = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default RootThemeProvider

import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  language: 'PL'
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      const language = action.payload
      return { ...state, language: language }
    }
  }
})

export const { setLanguage } = languageSlice.actions

export default languageSlice.reducer

import React from 'react'
import { ButtonGroup } from '@mui/material'
import SocialLink from './links/Social'
import TwitterLogo from '../../assets/images/logos/twitter.svg'
import OpenSeaLogo from '../../assets/images/logos/opensea_logo_white.svg'
import DiscordLogo from '../../assets/images/logos/discord.svg'

export default function SocialLinks () {
  return (
    <ButtonGroup
      disableElevation
      disableRipple
      variant='string'
      size='large'
      color='inherit'
    >
      <SocialLink
        logo={DiscordLogo}
        altText='discord'
        href='https://discord.gg/murall'
      />
      <SocialLink
        logo={TwitterLogo}
        altText='twitter'
        href='https://twitter.com/Slugbunnies_nft'
      />
      <SocialLink
        logo={OpenSeaLogo}
        altText='opensea'
        href='https://opensea.io/collection/frames-by-murall'
      />
    </ButtonGroup>
  )
}

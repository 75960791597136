import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import Fade from '../../reveal/in-and-out/Fade'
import { useTheme } from '@mui/material/styles'

const CustomAccordion = ({ items = [] }) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false)

  const createAccordionSection = ({ id, title, content }) => {
    return (
      <Fade top cascade>
        <Accordion
          disableGutters
          elevation={0}
          key={id}
          expanded={expanded === id}
          onChange={handleChange(id)}
        >
          <AccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon fontSize='inherit' />}
          >
            <Title
              variant='h6'
              component='h6'
              sx={{
                fontWeight: 300,
                color: theme.palette.primary.main,
                fontFamily:
                  '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                textShadow: '0px 0px 8px white'
              }}
            >
              {title}
            </Title>
          </AccordionSummary>
          <MuiAccordionDetails>
            <Content>{content}</Content>
          </MuiAccordionDetails>
        </Accordion>
      </Fade>
    )
  }

  return items.map((item, idx) =>
    createAccordionSection({
      title: item.title,
      content: item.content,
      id: `item-${idx}`
    })
  )
}

const Accordion = styled(MuiAccordion)`
  flex: 1;
  width: 100%;
  transition: all 300ms;
  background-color: ${({ theme, expanded }) =>
    expanded ? theme.palette.primary.main + '50' : 'transparent'};
  &:before {
    display: none;
  }
`

const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`

const Title = styled(Typography)`
  font-family: "Chelsea Market", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  padding-left: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.primary.main};
  text-shadow: 2px 2px 5px white;
`

const Content = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: "Chelsea Market", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  padding: 0 ${({ theme }) => theme.spacing(4)};
  text-shadow: 2px 2px 5px white;
`

export default CustomAccordion

import thisway from '../assets/images/slugbunny_bay_sign_cropped.png'
import subSebastian from '../assets/images/icon_sub_sebastian.png'
import BackgroundImage from '../assets/images/backgrounds/slugbunnies_bg_underwater_min.png'
import BackgroundImageSmall from '../assets/images/backgrounds/slugbunnies_bg_underwater_tiny.png'
import '../App.css'
import { scroller, animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import DefaultContainer from '../components/common/DefaultContainer'
import { useEffect } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Fade from '../reveal/in-and-out/Fade'
import slugbunniesAudio from '../assets/audio/Slugbunnies_SFX.mp3'
import ImageWithDescription from '../components/common/ImageWithDescription'
import LazyImage from '../components/common/LazyImage'
import useAudio from '../components/common/useAudio'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'

const MAX_WIDTH = 1200

function Lore() {
  const theme = useTheme()

  const location = useLocation()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const [playing, toggle] = useAudio(slugbunniesAudio)

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1)
      if (section) {
        scroller.scrollTo(section, { smooth: true })
      }
    } else {
      animateScroll.scrollToTop()
    }
  }, [location])
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <LazyImage
        src={BackgroundImage}
        lqip={BackgroundImageSmall}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          minHeight: '100vh',
          objectPosition: 'center top',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />

      <Box
        sx={{
          marginTop: isMobile ? '48px' : '148px',
          width: '100%',
          height: '100%',
          minHeight: `calc(100vh - ${isMobile ? '48px' : '148px'} - 72px)`,
        }}
      >
        <Fade bottom cascade>
          <DefaultContainer
            style={{
              maxWidth: `${MAX_WIDTH}px`,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <ImageWithDescription
              reversed
              imageSrc={thisway}
              imageSm={3}
              textSm={9}
            >
              <Typography
                align={isMobile ? 'center' : 'justify'}
                pl={isMobile ? 0 : 10}
                pr={isMobile ? 0 : 10}
                mb={5}
                mt={5}
                sx={{
                  color: theme.palette.primary.main,
                  textShadow: '0px 0px 32px white',
                  fontFamily:
                    '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                  fontWeight: 500,
                  fontSize: '23px',
                  // lineHeight: '41px'
                }}
              >
                <Typography
                  display="inline"
                  component="p"
                  sx={{
                    textShadow: '0px 0px 32px white',
                    fontFamily:
                      '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                    fontWeight: 700,
                    fontStyle: 'italic',
                    fontSize: '23px',
                    // lineHeight: '41px'
                  }}
                >
                  Slugbunnies
                </Typography>{' '}
                are adventurous creatures with a little ‘tude – which comes in
                handy when exploring the swirling waters of Slugbunny Bay – a
                place where the ocean currents act as highways, carrying
                creatures far and wide… past the beauty of their coral homes,
                the glistening piazza and the endless supply of delicious sea
                sponges, to… the shadowy waters of Murky Grotto. As these teeny
                sea creatures know, life can be unpredictable in the ocean. But
                if you just go with the flow…
                <br />
                <br />
                Life’s{' '}
                <Typography
                  display="inline"
                  component="p"
                  sx={{
                    textShadow: '0px 0px 32px white',
                    fontFamily:
                      '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                    fontWeight: 700,
                    fontStyle: 'bold',
                    fontSize: '23px',
                    lineHeight: '41px',
                  }}
                >
                  BIGGEST adventures
                </Typography>{' '}
                can be found in the smallest of places!
              </Typography>
            </ImageWithDescription>
          </DefaultContainer>
          <DefaultContainer
            style={{
              paddingTop: '36px',
              maxWidth: `400px`,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%',
                height: '100%',
                pl: 2,
                pr: 2,
              }}
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  endIcon={playing ? <PauseIcon /> : <PlayArrowIcon />}
                  onClick={toggle}
                  sx={{
                    fontFamily:
                      '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                  }}
                >
                  {playing ? 'Pause' : 'Play'}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Fade right>
                  <img
                    style={{
                      width: '400px',
                      objectFit: 'contain',
                    }}
                    src={subSebastian}
                    alt=""
                  />
                </Fade>
              </Grid>
            </Grid>
          </DefaultContainer>
        </Fade>
      </Box>
    </Box>
  )
}

export default Lore

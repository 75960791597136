import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import Routes from './routes'
import ThemeProvider from './theme/provider'
import store from './state/store'
import RoutesComingSoon from './routes_comingsoon'

const element = document.getElementById('loading_screen')
element.remove()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store()}>
      <ThemeProvider>
        <Router>
        {/* uncomment when going live */}
          <Routes />
          {/* <RoutesComingSoon /> */}
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

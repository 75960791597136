import BackgroundImage from '../assets/images/backgrounds/slugbunnies_bay_full_min.png'
import BackgroundImageSmall from '../assets/images/backgrounds/slugbunnies_bay_tiny.png'
import logo from '../assets/images/logos/slugbunny_logo.png'
import '../App.css'
import { scroller, animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import DefaultContainer from '../components/common/DefaultContainer'
import { useEffect } from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import Fade from '../reveal/in-and-out/Fade'
import LazyImage from '../components/common/LazyImage'

const MAX_WIDTH = 1200

function ComingSoon() {
  const theme = useTheme()

  const location = useLocation()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1)
      if (section) {
        scroller.scrollTo(section, { smooth: true })
      }
    } else {
      animateScroll.scrollToTop()
    }
  }, [location])
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <LazyImage
        src={BackgroundImage}
        lqip={BackgroundImageSmall}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          minHeight: '100vh',
          objectPosition: 'center center',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          minHeight: `100vh`,
        }}
      >
        <Fade bottom cascade>
          <DefaultContainer
            style={{
              maxWidth: `${MAX_WIDTH}px`,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              style={{
                width: '100%',
                maxHeight: '200px',
                padding: isMobile ? 2 : 10,
                objectFit: 'contain',
              }}
              src={logo}
              alt=""
            />

            <Typography
              align="center"
              variant={isMobile ? 'h5' : 'h4'}
              pl={isMobile ? 2 : 10}
              pr={isMobile ? 2 : 10}
              mb={2}
              mt={10}
              sx={{
                textShadow: '0px 0px 32px white',
                color: theme.palette.primary.main,
                fontFamily:
                  '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                fontWeight: 700,
              }}
            >
              Coming Soon…Squeeeeeeee!
            </Typography>
          </DefaultContainer>
        </Fade>
      </Box>
    </Box>
  )
}

export default ComingSoon

import React from 'react'
import { Route, Routes as Switch, Navigate } from 'react-router-dom'

import Layout from './components/common/Layout'
import App from './App'
import Team from './pages/Team'
import Lore from './pages/Lore'
import Mint from './pages/Mint'
import Seafloor from './pages/Seafloor'
import Clamshell from './pages/Clamshell'

const Routes = () => {
  return (
    <Layout>
      <Switch>
        <Route path='/clamshell' element={<Clamshell />} exact />
        <Route path='/seafloor' element={<Seafloor />} exact />
        <Route path='/mint' element={<Mint />} exact />
        <Route path='/lore' element={<Lore />} exact />
        <Route path='/team' element={<Team />} exact />
        <Route path='/' element={<App />} exact />
        <Route path='*' element={<App />} />
      </Switch>
    </Layout>
  )
}

export default Routes

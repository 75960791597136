import BackgroundImage from '../assets/images/backgrounds/slugbunnies_bay_full_min.png'
import BackgroundImageSmall from '../assets/images/backgrounds/slugbunnies_bay_full.png'
import '../App.css'
import { scroller, animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import DefaultContainer from '../components/common/DefaultContainer'
import { useEffect } from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import Fade from '../reveal/in-and-out/Fade'
import LazyImage from '../components/common/LazyImage'

const MAX_WIDTH = 1200

function Seafloor() {
  const theme = useTheme()

  const location = useLocation()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1)
      if (section) {
        scroller.scrollTo(section, { smooth: true })
      }
    } else {
      animateScroll.scrollToTop()
    }
  }, [location])
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <LazyImage
        src={BackgroundImage}
        lqip={BackgroundImageSmall}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          minHeight: '100vh',
          objectPosition: 'center top',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />

      <Box
        sx={{
          marginTop: isMobile ? '72px' : '148px',
          width: '100%',
          height: '100%',
          minHeight: `calc(100vh - ${isMobile ? '48px' : '148px'} - 72px)`,
        }}
      >
        <Fade bottom cascade>
          <DefaultContainer
            style={{
              maxWidth: `${MAX_WIDTH}px`,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              align={isMobile ? 'center' : 'justify'}
              variant={isMobile ? 'h6' : 'h6'}
              pl={isMobile ? 2 : 10}
              pr={isMobile ? 2 : 10}
              mb={10}
              sx={{
                textShadow: '0px 0px 32px white',
                color: theme.palette.primary.main,
                fontFamily:
                  '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
                fontSize: '23px',
                // lineHeight: '41px',
              }}
            >
              <Typography
                variant={isMobile ? 'h6' : 'h6'}
                display="inline"
                component="p"
                sx={{
                  textShadow: '0px 0px 32px white',
                  fontFamily:
                    '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                  fontWeight: 700,
                  fontStyle: 'italic',
                  fontSize: '23px',
                  // lineHeight: '41px',
                }}
              >
                Our Roadmap?
              </Typography>{' '}
              There are no roads in the ocean. We’re letting the currents carry
              us where we want to go, mapping our path along the way. Together,
              we’ll be swept forward as we build a family friendly, industry
              leading web3 brand based on solid animation experience, widespread
              industry connections, top-notch developers, and a love of
              entertainment in the kids’ space.
            </Typography>
          </DefaultContainer>
        </Fade>
      </Box>
    </Box>
  )
}

export default Seafloor

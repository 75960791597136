import background1 from './assets/images/backgrounds/slugbunnies_bg.png'
import background2 from './assets/images/backgrounds/slugbunnies_bay_full_min.png'
import background3 from './assets/images/backgrounds/slugbunnies_bg_sand.png'
import slugbunnyStill from './assets/images/slugbunnies/16.png'
import slugbunniesExamples from './assets/images/slugbunnies/examples_optimized_smaller.gif'
import './App.css'
import { Element as ScrollElement, scroller, animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Parallax } from 'react-parallax'
import Box from '@mui/material/Box'
import Hero from './sections/Hero'
import DefaultContainer from './components/common/DefaultContainer'
import DescriptionWithImage from './components/common/DescriptionWithImage'
import { useEffect } from 'react'
import FaqSection from './sections/Faq'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
const MAX_WIDTH = 1200
const MAX_WIDTH_HERO = 1500

function App() {
  const theme = useTheme()
  const location = useLocation()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1)
      if (section) {
        scroller.scrollTo(section, { smooth: true })
      }
    } else {
      animateScroll.scrollToTop()
    }
  }, [location])
  return (
    <Box>
      <ScrollElement name="home">
        <Hero maxContentWidth={`${MAX_WIDTH_HERO}px`} />
      </ScrollElement>
      <ScrollElement name="about">
        <Parallax
          bgImage={background1}
          bgImageAlt="the dog"
          strength={600}
          style={
            {
              // marginTop: isMobile ? '48px' : '148px'
            }
          }
        >
          <DefaultContainer
            className="bgGlow"
            style={{
              marginTop: '248px',
              maxWidth: `${MAX_WIDTH}px`,
              marginBottom: '248px',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <DescriptionWithImage reversed imageSrc={slugbunnyStill}>
              <Typography
                variant={isMobile ? 'body1' : 'h5'}
                align={isMobile ? 'center' : 'justify'}
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '23px',
                  fontFamily:
                    '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                }}
              >
                <Typography
                  display="inline"
                  component="p"
                  sx={{
                    fontFamily:
                      '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                    fontWeight: 700,
                    fontStyle: 'italic',
                    fontSize: '23px',
                    lineHeight: '41px',
                  }}
                >
                  Slugbunnies
                </Typography>{' '}
                are adventurous creatures with a little ‘tude – which comes in
                handy when exploring the swirling waters of Slugbunny Bay – a
                place where the ocean currents act as highways, carrying
                creatures far and wide… past the beauty of their coral homes,
                the glistening piazza and the endless supply of delicious sea
                sponges, to… the shadowy waters of Murky Grotto. As these teeny
                sea creatures know, life can be unpredictable in the ocean. But
                if you just go with the flow…
                <br />
                <br />
                Life’s{' '}
                <Typography
                  display="inline"
                  component="p"
                  sx={{
                    fontFamily:
                      '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                    fontWeight: 700,
                    fontStyle: 'italic',
                    fontSize: '23px',
                    lineHeight: '41px',
                  }}
                >
                  BIGGEST
                </Typography>{' '}
                adventures can be found in the smallest of places!
              </Typography>
            </DescriptionWithImage>
          </DefaultContainer>
        </Parallax>
      </ScrollElement>
      <Parallax
        bgImage={background2}
        bgImageAlt="the dog"
        strength={600}
        style={
          {
            // marginTop: isMobile ? '48px' : '148px'
          }
        }
      >
        <DefaultContainer
          style={{
            marginTop: '248px',
            maxWidth: `${MAX_WIDTH}px`,
            marginBottom: '248px',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <DescriptionWithImage
            title={'Let’s Make a Splash!'}
            imageSrc={slugbunniesExamples}
          >
            <Typography
              variant={isMobile ? 'body1' : 'h5'}
              align={isMobile ? 'center' : 'justify'}
              sx={{
                color: theme.palette.primary.main,
                fontSize: '23px',
                fontFamily:
                  '"Chelsea Market", "Helvetica", "Arial", sans-serif',
              }}
            >
              Each animated and musical Slugbunnies NFT comes with its own set
              of attributes, including one of 37 custom-drawn expressions, and a
              variety of colorful spots and stripes, hats, bows, glasses,
              distinctive ears and tails, ‘slug spots’ and other oddities –
              making each and every Slugbunny aaaadorable!
            </Typography>
          </DescriptionWithImage>
        </DefaultContainer>
      </Parallax>

      <ScrollElement name="faq">
        <Parallax
          bgImage={background3}
          bgImageAlt="the dog"
          strength={600}
          style={
            {
              // marginTop: isMobile ? '48px' : '148px'
            }
          }
        >
          <FaqSection />
        </Parallax>
      </ScrollElement>
    </Box>
  )
}

export default App

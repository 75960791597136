import BackgroundImage from '../assets/images/backgrounds/slugbunnies_bg_underwater_min.png'
import BackgroundImageSmall from '../assets/images/backgrounds/slugbunnies_bg_underwater_tiny.png'
import '../App.css'
import { scroller, animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import DefaultContainer from '../components/common/DefaultContainer'
import { useEffect } from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Fade from '../reveal/in-and-out/Fade'
import LazyImage from '../components/common/LazyImage'


const MAX_WIDTH = 1200

function Mint () {
  const theme = useTheme()

  const location = useLocation()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1)
      if (section) {
        scroller.scrollTo(section, { smooth: true })
      }
    } else {
      animateScroll.scrollToTop()
    }
  }, [location])
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      <LazyImage
        src={BackgroundImage}
        lqip={BackgroundImageSmall}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          minHeight: '100vh',
          objectPosition: 'center top',
          objectFit: 'cover',
          zIndex: -1
        }}
      />
      <Box
        sx={{
          marginTop: isMobile ? '48px' : '148px',
          width: '100%',
          height: '100%',
          minHeight: `calc(100vh - ${isMobile ? '48px' : '148px'} - 72px)`
        }}
      >
        <Fade bottom cascade>
          <DefaultContainer
            style={{
              maxWidth: `${MAX_WIDTH}px`,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              align='center'
              variant={isMobile ? 'h5' : 'h4'}
              pl={isMobile ? 2 : 10}
              pr={isMobile ? 2 : 10}
              mb={2}
              mt={10}
              sx={{
                textShadow: '0px 0px 32px white',
                color: theme.palette.primary.main,
                fontFamily:
                  '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                fontWeight: 700
              }}
            >
              Let’s Make a Splash!
            </Typography>
          </DefaultContainer>
          <DefaultContainer
            style={{
              maxWidth: `${MAX_WIDTH}px`,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              align='justify'
              variant={isMobile ? 'h6' : 'h6'}
              pl={isMobile ? 2 : 10}
              pr={isMobile ? 2 : 10}
              mb={10}
              sx={{
                textShadow: '0px 0px 32px white',
                color: theme.palette.primary.main,
                fontFamily:
                  '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
                fontSize: '23px',
                lineHeight: '41px'
              }}
            >
              Each gently animated, musical{' '}
              <Typography
                variant={isMobile ? 'h6' : 'h6'}
                display='inline'
                component='p'
                sx={{
                  textShadow: '0px 0px 32px white',
                  fontFamily:
                    '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                  fontWeight: 700,
                  fontStyle: 'italic',
                  fontSize: '23px',
                  lineHeight: '41px'
                }}
              >
                Slugbunnies NFT
              </Typography>{' '}
              comes with its own set of attributes, including one of 37
              custom-drawn expressions, and a variety of colorful spots and
              stripes, hats, bows, glasses, distinctive ears and tails, ‘slug
              spots’ and other oddities – making each and every Slugbunny
              aaaadorable!
            </Typography>
          </DefaultContainer>
        </Fade>
      </Box>
    </Box>
  )
}

export default Mint

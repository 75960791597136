import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const SectionTitle = ({ title }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Title
      variant={isMobile ? 'h4' : 'h3'}
      component='p'
      align='center'
      sx={{
        fontWeight: 300,
        fontFamily: '"Chelsea Market", "Helvetica", "Arial", sans-serif'
      }}
    >
      {title}
    </Title>
  )
}

const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  width: 100%;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.primary.main};
  text-shadow: 2px 2px 5px white;
`

export default SectionTitle

import React from 'react'
import { Parallax, Background } from 'react-parallax'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Grid, Typography } from '@mui/material'
import logo from '../assets/images/logos/slugbunny_logo.png'
import slugbunny0 from '../assets/images/slugbunnies/0.png'
import slugbunny1 from '../assets/images/slugbunnies/1.png'
import slugbunny2 from '../assets/images/slugbunnies/2.png'
import slugbunny3 from '../assets/images/slugbunnies/3.png'
import slugbunny4 from '../assets/images/slugbunnies/4.png'
import slugbunny5 from '../assets/images/slugbunnies/5.png'
import slugbunny6 from '../assets/images/slugbunnies/6.png'
import slugbunny7 from '../assets/images/slugbunnies/7.png'
import slugbunny8 from '../assets/images/slugbunnies/8.png'
import slugbunny9 from '../assets/images/slugbunnies/9.png'
import slugbunny10 from '../assets/images/slugbunnies/10.png'
import slugbunny11 from '../assets/images/slugbunnies/11.png'
import slugbunny12 from '../assets/images/slugbunnies/12.png'
import slugbunny13 from '../assets/images/slugbunnies/13.png'
import slugbunny14 from '../assets/images/slugbunnies/14.png'
import slugbunny15 from '../assets/images/slugbunnies/15.png'
import slugbunny16 from '../assets/images/slugbunnies/16.png'
import slugbunny17 from '../assets/images/slugbunnies/17.png'
import slugbunny18 from '../assets/images/slugbunnies/18.png'
import slugbunny19 from '../assets/images/slugbunnies/19.png'
import slugbunny20 from '../assets/images/slugbunnies/20.png'
import slugbunny21 from '../assets/images/slugbunnies/21.png'
import slugbunny22 from '../assets/images/slugbunnies/22.png'
import slugbunny23 from '../assets/images/slugbunnies/23.png'
import slugbunny24 from '../assets/images/slugbunnies/24.png'
import slugbunny25 from '../assets/images/slugbunnies/25.png'
import slugbunny26 from '../assets/images/slugbunnies/26.png'
import slugbunny27 from '../assets/images/slugbunnies/27.png'
import slugbunny28 from '../assets/images/slugbunnies/28.png'
import slugbunny29 from '../assets/images/slugbunnies/29.png'
import slugbunny0min from '../assets/images/slugbunnies/0_min.png'
import slugbunny1min from '../assets/images/slugbunnies/1_min.png'
import slugbunny2min from '../assets/images/slugbunnies/2_min.png'
import slugbunny3min from '../assets/images/slugbunnies/3_min.png'
import slugbunny4min from '../assets/images/slugbunnies/4_min.png'
import slugbunny5min from '../assets/images/slugbunnies/5_min.png'
import slugbunny6min from '../assets/images/slugbunnies/6_min.png'
import slugbunny7min from '../assets/images/slugbunnies/7_min.png'
import slugbunny8min from '../assets/images/slugbunnies/8_min.png'
import slugbunny9min from '../assets/images/slugbunnies/9_min.png'
import slugbunny10min from '../assets/images/slugbunnies/10_min.png'
import slugbunny11min from '../assets/images/slugbunnies/11_min.png'
import slugbunny12min from '../assets/images/slugbunnies/12_min.png'
import slugbunny13min from '../assets/images/slugbunnies/13_min.png'
import slugbunny14min from '../assets/images/slugbunnies/14_min.png'
import slugbunny15min from '../assets/images/slugbunnies/15_min.png'
import slugbunny16min from '../assets/images/slugbunnies/16_min.png'
import slugbunny17min from '../assets/images/slugbunnies/17_min.png'
import slugbunny18min from '../assets/images/slugbunnies/18_min.png'
import slugbunny19min from '../assets/images/slugbunnies/19_min.png'
import slugbunny20min from '../assets/images/slugbunnies/20_min.png'
import slugbunny21min from '../assets/images/slugbunnies/21_min.png'
import slugbunny22min from '../assets/images/slugbunnies/22_min.png'
import slugbunny23min from '../assets/images/slugbunnies/23_min.png'
import slugbunny24min from '../assets/images/slugbunnies/24_min.png'
import slugbunny25min from '../assets/images/slugbunnies/25_min.png'
import slugbunny26min from '../assets/images/slugbunnies/26_min.png'
import slugbunny27min from '../assets/images/slugbunnies/27_min.png'
import slugbunny28min from '../assets/images/slugbunnies/28_min.png'
import slugbunny29min from '../assets/images/slugbunnies/29_min.png'
import BackgroundImage from '../assets/images/backgrounds/slugbunnies_bay_full_min.png'
import BackgroundImageSmall from '../assets/images/backgrounds/slugbunnies_bay_tiny.png'
import BackgroundImageMobile from '../assets/images/backgrounds/slugbunnies_bg_underwater_min.png'
import BackgroundImageMobileSmall from '../assets/images/backgrounds/slugbunnies_bg_underwater_tiny.png'
import ScrollDownButton from '../components/common/buttons/ScrollDown'
import LazyImage from '../components/common/LazyImage'
import DefaultContainer from '../components/common/DefaultContainer'
import Fade from '../reveal/in-and-out/Fade'
import { useTheme } from '@mui/material/styles'
import Carousel from '../components/common/Carousel'
import HeroCustomContent from '../components/common/HeroCustomContent'

export default function Hero (props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const createImage = (imgPath, index) => (
    <img
      src={imgPath}
      alt=''
      style={{
        position: 'absolute',
        width: `100%`,
        height: '100%',
        objectFit: 'contain',
        padding: 0
      }}
    />
  )

  const createLazyImage = (details, index) => (
    <LazyImage
      src={details.full}
      lqip={details.min}
      style={{
        position: 'absolute',
        width: `100%`,
        height: '100%',
        objectFit: 'contain',
        padding: 0
      }}
    />
  )

  return (
    <Parallax strength={600}>
      <Background>
        <LazyImage
          src={isMobile ? BackgroundImageMobile : BackgroundImage}
          lqip={isMobile ? BackgroundImageMobileSmall : BackgroundImageSmall}
          style={{
            width: '100vw',
            height: '100vh',
            minHeight: '100vh',
            objectPosition: 'center center',
            objectFit: 'cover'
          }}
        />
      </Background>
      <DefaultContainer
        style={{
          marginTop: '60px',
          maxWidth: props.maxContentWidth
        }}
      >
        {isMobile ? (
          <Grid
            container
            direction={'row'}
            justify={'center'}
            alignItems={'stretch'}
            mt={10}
            sx={{
              minHeight: '90vh',
              flexGrow: 1,
              height: '100%',
              width: '100%'
            }}
          >
            <Grid justifyContent='center' alignItems='center' item xs={12}>
              <Carousel
                style={{
                  width: '100%',
                  height: '100%',
                  margin: 0,
                  padding: 0
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  reverseDirection: false
                }}
                spaceBetween={5}
                slidesPerView={1.5}
                slides={[
                  { full: slugbunny0, min: slugbunny0min },
                  { full: slugbunny1, min: slugbunny1min },
                  { full: slugbunny2, min: slugbunny2min },
                  { full: slugbunny3, min: slugbunny3min },
                  { full: slugbunny4, min: slugbunny4min },
                  { full: slugbunny5, min: slugbunny5min },
                  { full: slugbunny6, min: slugbunny6min },
                  { full: slugbunny7, min: slugbunny7min },
                  { full: slugbunny8, min: slugbunny8min },
                  { full: slugbunny9, min: slugbunny9min },
                  { full: slugbunny10, min: slugbunny10min },
                  { full: slugbunny11, min: slugbunny11min },
                  { full: slugbunny12, min: slugbunny12min },
                  { full: slugbunny13, min: slugbunny13min },
                  { full: slugbunny14, min: slugbunny14min }
                ]}
                renderSlideContent={createLazyImage}
              />
            </Grid>
            <Grid
              justifyContent='center'
              alignItems='center'
              alignSelf={'center'}
              item
              xs={12}
            >
              <Fade bottom cascade>
                <img
                  style={{
                    width: '100%',
                    maxHeight: '100px',
                    objectFit: 'contain'
                  }}
                  src={logo}
                  alt=''
                />
                <Typography
                  variant={'h6'}
                  mt={2}
                  px={2}
                  sx={{
                    fontWeight: 300,
                    color: theme.palette.primary.main,
                    fontFamily:
                      '"Chelsea Market", "Helvetica", "Arial", sans-serif',
                    textShadow: '2px 2px 5px white'
                  }}
                  align='center'
                >
                  10,000 unique Slugbunnies… Swimming on the Ethereum blockchain
                  and slip-sliding into homes everywhere… Squeeeeeeee!
                </Typography>
              </Fade>
            </Grid>
          </Grid>
        ) : (
          <HeroCustomContent
            style={{
              minHeight: 'calc(100vh - 60px)',
              width: '100%'
            }}
            heroContent={
              <Grid
                container
                direction={'row'}
                justify={isMobile ? 'center' : 'space-between'}
                alignItems={isMobile ? 'center' : 'space-evenly'}
                sx={{
                  flexGrow: 1,
                  height: '100%',
                  width: '100%',
                  pb: 10,
                  pt: 10
                }}
              >
                <Grid justifyContent='center' alignItems='center' item xs={12}>
                  <Carousel
                    style={{
                      width: '100%',
                      height: '100%',
                      margin: 0,
                      padding: 0
                    }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                      reverseDirection: false
                    }}
                    spaceBetween={15}
                    slidesPerView={2.15}
                    slides={[
                      { full: slugbunny0, min: slugbunny0min },
                      { full: slugbunny1, min: slugbunny1min },
                      { full: slugbunny2, min: slugbunny2min },
                      { full: slugbunny3, min: slugbunny3min },
                      { full: slugbunny4, min: slugbunny4min },
                      { full: slugbunny5, min: slugbunny5min },
                      { full: slugbunny6, min: slugbunny6min },
                      { full: slugbunny7, min: slugbunny7min },
                      { full: slugbunny8, min: slugbunny8min },
                      { full: slugbunny9, min: slugbunny9min },
                      { full: slugbunny10, min: slugbunny10min },
                      { full: slugbunny11, min: slugbunny11min },
                      { full: slugbunny12, min: slugbunny12min },
                      { full: slugbunny13, min: slugbunny13min },
                      { full: slugbunny14, min: slugbunny14min }
                    ]}
                    renderSlideContent={createLazyImage}
                  />
                </Grid>

                <Grid justifyContent='center' alignItems='center' item xs={12}>
                  <Carousel
                    style={{
                      width: '100%',
                      height: '100%',
                      margin: 0,
                      padding: 0
                    }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                      reverseDirection: true
                    }}
                    spaceBetween={15}
                    slidesPerView={2.15}
                    slides={[
                      { full: slugbunny15, min: slugbunny15min },
                      { full: slugbunny16, min: slugbunny16min },
                      { full: slugbunny17, min: slugbunny17min },
                      { full: slugbunny18, min: slugbunny18min },
                      { full: slugbunny19, min: slugbunny19min },
                      { full: slugbunny20, min: slugbunny20min },
                      { full: slugbunny21, min: slugbunny21min },
                      { full: slugbunny22, min: slugbunny22min },
                      { full: slugbunny23, min: slugbunny23min },
                      { full: slugbunny24, min: slugbunny24min },
                      { full: slugbunny25, min: slugbunny25min },
                      { full: slugbunny26, min: slugbunny26min },
                      { full: slugbunny27, min: slugbunny27min },
                      { full: slugbunny28, min: slugbunny28min },
                      { full: slugbunny29, min: slugbunny29min }
                    ]}
                    renderSlideContent={createLazyImage}
                  />
                </Grid>
              </Grid>
            }
          >
            <Fade bottom cascade>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  style={{
                    padding: '4em',
                    height: '170px',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                  src={logo}
                  alt=''
                />
                <Typography
                  variant={isMobile ? 'h5' : 'h4'}
                  sx={{
                    fontWeight: 300,
                    color: theme.palette.primary.main,
                    textShadow: '0px 0px 8px white',
                    fontFamily:
                      '"Chelsea Market", "Helvetica", "Arial", sans-serif'
                  }}
                  p={2}
                  align='center'
                  gutterBottom
                >
                  10,000 unique Slugbunnies… Swimming on the Ethereum blockchain
                  and slip-sliding into homes everywhere… Squeeeeeeee!
                </Typography>
              </Box>
            </Fade>
          </HeroCustomContent>
        )}
      </DefaultContainer>
      {!isMobile && <ScrollDownButton section='about' />}
    </Parallax>
  )
}

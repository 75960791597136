import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Grid } from '@mui/material'
import Fade from '../../reveal/in-and-out/Fade'

export default function HeroCustomContent (props) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Grid
      {...props}
      container
      rowSpacing={0}
      columnSpacing={{ xs: 0, md: 6 }}
      px={{ xs: 2, md: 6 }}
      py={2}
      direction={'row'}
      justify={isMobile ? 'center' : 'space-between'}
      alignItems={isMobile ? 'center' : 'space-evenly'}
      sx={{
        flexGrow: 1
      }}
    >
      <Grid
        justifyContent='center'
        alignItems='center'
        item
        md={6}
        sm={6}
        xs={12}
        style={{
          // backgroundColor: '#00ffff'
        }}
      >
        {props.heroContent}
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={6}
        direction='row'
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        style={{
          // backgroundColor: '#00ff00'
        }}
      >
        {props.children}
      </Grid>
    </Grid>
  )
}
